<template>
  <div class="site-pointcloud">
    <iframe
      :src="pointCloudUrl"
      class="w-100 h-100 shadow-sm"
      v-if="pointCloudUrl"
      @load="$emit('toggle-spinner', false)"
    />
    <div class="col p-2 text-center bg-dark rounded shadow-sm" v-else>
      <p class="text-center font-italic mb-0 py-3">No data available</p>
    </div>
    <button class="btn btn-fab btn-primary shadow-sm" @click="addUrl()">
      <i class="fas fa-pencil-alt" v-if="pointCloudUrl" />
      <i class="fas fa-plus" v-else />
    </button>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "component-site-point-cloud",
  props: ["data", "siteId"],
  data() {
    return {
      pointCloudUrl: "",
    };
  },
  methods: {
    async addUrl() {
      this.isAddingUrl = true;

      const { value: url } = await Swal.fire({
        icon: "info",
        title: "<h5 class='mb-0'>Enter Point Cloud URL</h5>",
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Save",
        input: "text",
        inputValue: this.pointCloudUrl,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary mx-1",
          cancelButton: "btn btn-secondary mx-1",
          input: "text-default-dark",
        },
      });

      if (url) {
        this.$emit("toggle-spinner", true);

        const body = {
          url: url,
          site: this.siteId,
        };

        let method = "post";
        let endpoint = "point-clouds";

        if (this.pointCloudUrl) {
          method = "put";

          endpoint += `/${this.data._id}`;
        }

        const [call, err] = await this.Helper.handle(
          this.API[method](endpoint, body)
        );

        if (!err && call.status == 200) {
          this.pointCloudUrl = url;

          this.$emit("update-site-data", {
            field: "point_cloud",
            data: call.data,
          });

          this.$emit("toggle-spinner", false);
        }
      }
    },
  },
  mounted() {
    if (this.data && this.data.url) {
      this.pointCloudUrl = this.data.url;

      this.$emit("toggle-spinner", true);
    } else {
      this.pointCloudUrl = "";
    }
  },
};
</script>