<template>
  <div class="mapbox-popup-container">
    <div
      class="
        popup-header
        line-height-sm
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <span class="text-nowrap text-truncate" :title="data.name || 'Pin'">
        {{ data.name || "Pin" }}<br />
        <small class="text-muted">{{ data.date }}</small>
      </span>
      <div class="text-nowrap ml-3">
        <button
          class="btn btn-light btn-sm mr-2"
          data-toggle="tooltip"
          title="Clone this pin"
          @mouseenter="Helper.showTooltip($event)"
          @click="clonePin(data.lat, data.lng)"
        >
          <i class="far fa-copy" />
        </button>
        <button
          class="btn btn-danger btn-sm"
          data-toggle="tooltip"
          title="Delete this pin"
          @mouseenter="Helper.showTooltip($event)"
          @click="deletePin(data._id)"
        >
          <i class="fas fa-trash-alt" />
        </button>
      </div>
    </div>
    <div class="popup-content">
      <div class="p-3 text-center" v-if="isUrl">
        <button
          class="btn btn-primary btn-sm"
          @click="
            viewImage({
              url: data.url,
              mime: data.isVideo ? 'video' : 'iframe',
            })
          "
        >
          View Media
        </button>
      </div>
      <img
        class="w-100 h-auto clickable"
        :src="imageUrl"
        @click="viewImage(data.media)"
        v-else
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      isUrl: false,
    };
  },
  computed: {
    imageUrl() {
      if (this.data) {
        if (
          this.data.media &&
          this.data.media.formats &&
          this.data.media.formats.thumbnail
        ) {
          return this.Helper.formatMediaUrl(this.data.media.formats.thumbnail);
        } else if (this.data.media) {
          return this.Helper.formatMediaUrl(this.data.media);
        } else if (this.data.url) {
          this.isUrl = true;

          return this.data.url;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (Math.ceil(this.$el.clientWidth) % 2) {
      this.$el.style.width = Math.ceil(this.$el.clientWidth) + 1 + "px";
    }
  },
};
</script>