var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-report overflow-auto container-fluid position-relative"},[_c('button',{staticClass:"btn btn-fab btn-primary shadow-sm",on:{"click":function($event){return _vm.showReportForm()}}},[_c('i',{staticClass:"fas fa-plus"})]),(_vm.reports.length > 0)?_c('div',{staticClass:"table-responsive bg-dark rounded mt-2"},[_c('table',{staticClass:"table table-borderless table-striped mb-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.reports),function(report,index){return _c('tr',{key:report._id,staticClass:"text-default-dark"},[_c('td',{staticClass:"align-middle",staticStyle:{"width":"1%"}},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(report.name))]),_c('td',{staticClass:"text-center text-nowrap align-middle"},[_vm._v(" "+_vm._s(_vm.Helper.formatDate(report.date, "DD-MM-YYYY"))+" ")]),_c('td',{staticClass:"text-center text-nowrap",staticStyle:{"width":"1%"}},[_c('button',{staticClass:"btn btn-sm btn-info text-light mx-1",attrs:{"title":"Download report"},on:{"click":function($event){return _vm.downloadReport(report)}}},[_c('i',{staticClass:"fas fa-download"})]),_c('button',{staticClass:"btn btn-sm btn-primary text-light mx-1",attrs:{"title":"View report"},on:{"click":function($event){return _vm.viewReport(report)}}},[_c('i',{staticClass:"fas fa-eye"})]),_c('button',{staticClass:"btn btn-sm btn-danger mx-1",attrs:{"title":"Delete report"},on:{"click":function($event){return _vm.deleteReport(report._id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])}),0)])]):_c('div',{staticClass:"bg-dark p-2 shadow-sm rounded"},[_c('p',{staticClass:"text-center font-italic mb-0 py-3"},[_vm._v("No report found")])]),(_vm.reportForm.show)?_c('report-form-modal',{attrs:{"site":{
      _id: _vm.data ? _vm.data._id : null,
      name: _vm.data ? _vm.data.name : null,
      lat: _vm.data ? _vm.data.lat : null,
      lng: _vm.data ? _vm.data.lng : null,
      tiles: _vm.data && _vm.data.tiles && _vm.data.tiles.length > 0 ? _vm.data.tiles : [],
    }},on:{"toggle-spinner":function($event){return _vm.$emit('toggle-spinner', $event)},"close":_vm.closeReportForm}}):_vm._e(),(_vm.currentReport)?_c('media-preview',{attrs:{"title":("" + (_vm.currentReport.name)),"subtitle":_vm.data ? _vm.data.name : '',"media":_vm.currentReport.media && _vm.currentReport.media.length > 0
        ? _vm.currentReport.media[0]
        : null},on:{"close":function($event){_vm.currentReport = null}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"thead-dark"},[_c('tr',{staticClass:"text-default"},[_c('th',{staticStyle:{"width":"1%"}},[_vm._v("No")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Report Title")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"1%"}},[_vm._v("Date")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"1%"}})])])}]

export { render, staticRenderFns }