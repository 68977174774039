<template>
  <div class="floating-form" :class="{ show: show }">
    <div
      class="p-3 h5 mb-0 border-bottom border-default-dark bg-dark d-flex justify-content-between align-items-center"
    >
      <small class="font-weight-medium text-default-dark">Pin</small>
      <button class="btn p-0 text-muted" @click="close()">
        <i class="fas fa-times" />
      </button>
    </div>
    <div class="bg-default-dark p-3">
      <p
        class="small text-muted mb-1"
        v-if="mouseCoordinate && !mouseCoordinate.isClone"
      >
        Click on map to select coordinate
      </p>
      <div class="form-group">
        <label for="pinLatitude">
          Latitude<sup class="text-danger">*</sup>
        </label>
        <input
          id="pinLatitude"
          type="text"
          class="form-control"
          v-model="models.lat"
          :readonly="mouseCoordinate && mouseCoordinate.isClone"
          @input="sanitizeCoordinate('lat', $event)"
        />
      </div>
      <div class="form-group">
        <label for="pinLongitude">
          Longitude<sup class="text-danger">*</sup>
        </label>
        <input
          id="pinLongitude"
          type="text"
          class="form-control"
          v-model="models.lng"
          :readonly="mouseCoordinate && mouseCoordinate.isClone"
          @input="sanitizeCoordinate('lng', $event)"
        />
      </div>
      <div class="form-group">
        <label for="pinName">Name<sup class="text-danger">*</sup></label>
        <input
          id="pinName"
          type="text"
          class="form-control"
          v-model="models.name"
        />
      </div>
      <div class="form-group clickable" @click="showCalendarModal = true">
        <label for="layerDate">Date</label>
        <div class="form-control">
          <i class="fas fa-calendar-alt mr-2" />
          {{ models.date }}
        </div>
      </div>
      <div class="form-group">
        <label for="pinUrl">URL<sup class="text-muted">*</sup></label>
        <input
          id="pinUrl"
          type="text"
          class="form-control"
          v-model="models.url"
        />
      </div>
      <div class="form-group" v-if="models.url && models.url.length > 0">
        <label for="pinUrlType">URL Type<sup class="text-muted">*</sup></label>
        <div class="form-control p-0">
          <div class="btn-group w-100 h-100" role="group">
            <button
              type="button"
              class="btn btn-sm text-default-dark h-100 font-weight-medium"
              :class="{ 'btn-primary': models.isVideo == false }"
              @click="models.isVideo = false"
            >
              360
            </button>
            <button
              type="button"
              class="btn btn-sm text-default-dark h-100 font-weight-medium"
              :class="{ 'btn-primary': models.isVideo == true }"
              @click="models.isVideo = true"
            >
              VIDEO
            </button>
          </div>
        </div>
      </div>
      <label for="pinImage">Image<sup class="text-muted">*</sup></label>
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input cursor-pointer"
          id="pinImage"
          accept="image/*"
          @change="imageSelected"
        />
        <label
          class="custom-file-label text-nowrap text-truncate"
          for="pinImage"
        >
          {{ selectedFile ? selectedFile.name : "Choose file" }}
        </label>
      </div>
    </div>
    <button class="btn btn-primary btn-block py-2" @click="save()">Save</button>
    <date-picker-modal
      v-if="showCalendarModal"
      :value="models.date"
      @close="closeCalendarModal"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePickerModal from "@/components/DatePickerModal";

export default {
  props: ["show", "mouseCoordinate"],
  components: {
    DatePickerModal,
  },
  data() {
    return {
      selectedFile: null,
      models: {
        name: null,
        date: null,
        lat: null,
        lng: null,
        url: null,
        isVideo: false,
      },
      emptyModels: {
        name: null,
        date: null,
        lat: null,
        lng: null,
        url: null,
        isVideo: false,
      },
      errors: [],
      showCalendarModal: false,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show && this.$el) {
          this.$el.scrollTo(0, 0);
        }

        this.models = JSON.parse(JSON.stringify(this.emptyModels));

        this.selectedFile = null;
      },
      immediate: true,
    },
    mouseCoordinate: {
      handler() {
        if (this.mouseCoordinate.lat || this.mouseCoordinate.lng) {
          this.models.lat = this.mouseCoordinate.lat;
          this.models.lng = this.mouseCoordinate.lng;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    closeCalendarModal(e) {
      this.showCalendarModal = false;

      if (e !== "close") {
        this.models.date = e;
      }
    },
    async save() {
      this.errors = [];

      if (!this.models.name) {
        this.errors.push("Name is required");
      }

      if (!this.models.lat) {
        this.errors.push("Latitude is required");
      }

      if (!this.models.lng) {
        this.errors.push("Longitude is required");
      }

      if (!this.models.url && !this.selectedFile) {
        this.errors.push("URL/Image is required");
      }

      if (this.errors.length > 0) {
        Swal.fire({
          title: "<h5>Error</h5>",
          html: `<div class='line-height-md'>${this.errors.join(
            "<br />"
          )}</div>`,
          icon: "error",
        });
      } else {
        this.$emit("toggle-spinner", true);

        let body = JSON.parse(JSON.stringify(this.models));

        body.site = this.$route.params.id;

        let formData = new FormData();

        if (this.selectedFile) {
          formData.append(
            "files.media",
            this.selectedFile,
            this.selectedFile.name
          );
        }

        formData.append("data", JSON.stringify(body));

        const [call, err] = await this.Helper.handle(
          this.API.postForm("site-pins", formData)
        );

        if (!err && call.status == 200) {
          this.$emit("toggle-spinner", false);

          Swal.fire("<h5 class='mb-0'>Pin added</h5>", "", "success").then(
            () => {
              this.$emit("close", call.data);
            }
          );
        } else if (err) {
          this.$emit("toggle-spinner", false);

          Swal.fire("<h5 class='mb-0'>Error</h5>", err.message, "error");
        }
      }
    },
    sanitizeCoordinate(type, e) {
      this.models[type] = this.Helper.sanitizeNumber(e.target.value);
    },
    close(e) {
      const fileInput = document.getElementById("pinImage");

      if (fileInput) {
        fileInput.value = null;
      }

      this.$emit("close", e);
    },
    imageSelected(e) {
      this.selectedFile = e.target.files[0];
    },
  },
};
</script>