<template>
  <div class="position-relative map-view">
    <div class="w-100 h-100" v-if="site">
      <div
        class="site-floating-buttons bg-dark font-weight-bold d-flex justify-content-between align-items-center"
      >
        <ul class="nav site-nav flex-column h-100">
          <li
            class="nav-item"
            :class="{
              active: currentTab && tab.name == currentTab.name,
            }"
            v-for="tab in Helper.siteTabs().filter(
              (t) => !site.hidden_tabs.includes(t.name)
            )"
            :key="tab.name"
            data-placement="right"
            data-boundary="viewport"
            :title="tab.label"
            @mouseenter="Helper.showTooltip($event)"
          >
            <router-link
              class="btn h-100 font-weight-bold"
              :to="{ query: { tab: tab.name } }"
            >
              <i class="fas icon" :class="`fa-${tab.icon}`" />
              <!-- <span class="text">{{ tab.label }}</span> -->
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="side-right-content"> -->
        <temporal
          v-if="currentTab && currentTab.name == 'temporal' && site"
          :data="site"
          @toggle-spinner="toggleSpinner"
        />
        <site-gallery
          v-else-if="currentTab && currentTab.name == 'media_gallery'"
          :siteName="site.name"
          @toggle-spinner="toggleSpinner"
        />
        <point-cloud
          v-else-if="currentTab && currentTab.name == 'point_cloud' && site"
          @update-site-data="updateSiteData"
          :siteId="site._id"
          :data="site.point_cloud"
          @toggle-spinner="toggleSpinner"
        />
        <orthomosaic
          v-else-if="currentTab && currentTab.name == 'orthomosaic' && site"
          :data="site"
          @update-site-data="updateSiteData"
          @toggle-spinner="toggleSpinner"
        />
        <report
          v-else-if="currentTab && currentTab.name == 'report'"
          :data="site"
          @toggle-spinner="toggleSpinner"
        />
      <!-- </div> -->
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import SiteGallery from "@/components/site/Gallery.vue";
import Orthomosaic from "@/components/site/Orthomosaic.vue";
import PointCloud from "@/components/site/PointCloud.vue";
import Temporal from "@/components/site/Temporal.vue";
import Report from "@/components/site/Report.vue";

export default {
  name: "view-site",
  components: {
    Spinner,
    SiteGallery,
    Orthomosaic,
    PointCloud,
    Temporal,
    Report,
  },
  data() {
    return {
      isLoading: false,
      site: null,
      currentTab: null,
    };
  },
  watch: {
    "$route.params": {
      handler() {
        if (this.$route.params && this.$route.params.id) {
          this.getSite();
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.query": {
      handler() {
        if (this.$route.query.tab) {
          this.currentTab = this.Helper.siteTabs().find(
            (tab) =>
              !this.site.hidden_tabs.includes(tab.name) &&
              tab.name == this.$route.query.tab
          );
        } else {
          const firstCategory = this.Helper.siteTabs().find(
            (tab) => !this.site.hidden_tabs.includes(tab.name)
          );

          this.currentTab = firstCategory;
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    updateSiteData(e) {
      if (e && e.field) {
        if (e.type == "push") {
          this.site[e.field].push(e.data);
        } else {
          this.site[e.field] = e.data;
        }
      }
    },
    toggleSpinner(e) {
      this.isLoading = e;
    },
    async getSite() {
      this.isLoading = true;

      const [call, err] = await this.Helper.handle(
        this.API.get(`sites/${this.$route.params.id}`)
      );

      if (!err && call.status == 200) {
        const site = call.data;

        if (!site.hidden_tabs) {
          site.hidden_tabs = [];
        }

        this.site = site;

        if (this.$route.query.tab) {
          this.currentTab = this.Helper.siteTabs().find(
            (tab) =>
              !this.site.hidden_tabs.includes(tab.name) &&
              tab.name == this.$route.query.tab
          );
        } else {
          const firstCategory = this.Helper.siteTabs().find(
            (tab) => !this.site.hidden_tabs.includes(tab.name)
          );

          this.currentTab = firstCategory;
        }
      }

      this.isLoading = false;
    },
  },
};
</script>