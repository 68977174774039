<template>
  <div class="modal-custom">
    <div class="modal-custom-content">
      <div class="modal-custom-header">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <h1 class="d-flex justify-content-start align-items-center">
              {{ report ? "Edit" : "Add" }} Report
            </h1>
          </div>
        </div>
        <button class="btn border btn-close" @click="closePreview()">
          &times;
        </button>
      </div>
      <div class="modal-custom-body">
        <div class="form-group">
          <label for="reportSite">Site<sup class="text-danger">*</sup></label>
          <div class="form-control" readonly>
            {{ models.site ? models.site.name : "" }}
          </div>
        </div>
        <div class="form-group">
          <label for="reportName">
            Report Title<sup class="text-danger">*</sup>
          </label>
          <input
            id="reportName"
            type="text"
            class="form-control"
            v-model="models.name"
          />
        </div>
        <div class="form-group">
          <label for="reportDate">Date<sup class="text-danger">*</sup></label>
          <date-picker-dropdown
            btn-class="
              btn-light
              h-100
              text-left
              line-height-md
              d-flex
              justify-content-between
              align-items-center
            "
            :value="Helper.formatDate(models.date, 'YYYY-MM-DD')"
            @date-returned="dateReturned"
          />
        </div>
        <div class="form-group">
          <label for="reportMedia">File<sup class="text-danger">*</sup></label>
          <template>
            <div
              class="gallery-group p-0 d-flex align-items-start overflow-auto"
            >
              <div
                class="gallery-item p-0 mr-3 rounded position-relative hover-parent"
                v-for="(media, index) in models.media"
                :key="`media-upload-${index}`"
              >
                <button
                  class="btn btn-sm btn-danger position-absolute hover-item"
                  style="top: 0.25rem; right: 0.25rem; z-index: 2"
                  @click.stop="removeMedia(index)"
                >
                  <i class="fas fa-trash-alt" />
                </button>
                <div
                  class="embed-responsive rounded embed-responsive-1by1 bg-dark"
                >
                  <div
                    class="embed-responsive-item rounded d-flex justify-content-center align-items-center"
                  >
                    <i class="fas fa-file-pdf" />
                  </div>
                </div>
              </div>
              <div
                class="gallery-item p-0 rounded position-relative hover-parent"
                v-if="models.media.length < 1"
              >
                <div
                  class="embed-responsive rounded embed-responsive-1by1 bg-dark"
                >
                  <button
                    class="btn btn-light embed-responsive-item rounded d-flex flex-column justify-content-center align-items-center font-weight-medium text-center line-height-sm p-2"
                    @click="addMedia()"
                  >
                    <i class="fas fa-plus mb-1" />
                    <small>Add</small>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="modal-custom-footer">
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-sm btn-light" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-sm btn-primary" @click="saveReport()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePickerDropdown from "@/components/DatePickerDropdown";

export default {
  props: ["report", "site"],
  components: {
    DatePickerDropdown,
  },
  data() {
    return {
      models: {
        name: null,
        user: null,
        site: null,
        media: [],
        date: null,
      },
    };
  },
  methods: {
    dateReturned(e) {
      this.models.date = e;
    },
    removeMedia(index) {
      this.models.media = this.models.media.filter((m, idx) => idx != index);
    },
    addMedia() {
      let uploader = document.createElement("input");

      uploader.type = "file";
      uploader.accept = "image/*,application/pdf";
      uploader.multiple = false;

      uploader.onchange = (event) => {
        if (event.target.files.length < 1) return;

        let file = event.target.files[0];

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = (readerEvent) => {
          this.$nextTick(() => {
            this.models.media.push({
              file: file,
            });
          });
        };
      };

      uploader.click();
    },
    async saveReport() {
      // Save report
      if (
        !this.models.site ||
        !this.models.name ||
        this.models.media.length == 0
      ) {
        Swal.fire({
          title: "<h5>Error</h5>",
          html: "<div class='text-muted'>Error submitting report</div>",
          icon: "error",
        });
      } else {
        this.$emit("toggle-spinner", true);

        const formData = new FormData();

        const data = {};

        Object.keys(this.models).forEach((key) => {
          if (key !== "media") {
            if (["site", "user"].includes(key)) {
              data[key] = this.models[key] ? this.models[key]._id : null;
            } else {
              data[key] = this.models[key]
                ? this.models[key].toString().trim()
                : null;
            }
          } else {
            for (let i = 0; i < this.models[key].length; i++) {
              const file = this.models[key][i].file;

              formData.append(`files.${key}`, file, file.name);
            }
          }
        });

        formData.append("data", JSON.stringify(data));

        const [call, err] = await this.Helper.handle(
          this.API.postForm("reports", formData)
        );

        this.$emit("toggle-spinner", false);

        if (!err && call.status == 200) {
          Swal.fire({
            title: "<h5>Report submitted</h5>",
            icon: "success",
          }).then(() => {
            this.$nextTick(() => {
              this.$emit("close", call.data);
            });
          });
        } else {
          Swal.fire({
            title: "<h5>Error</h5>",
            html: "<div class='text-muted'>Error submitting report</div>",
            icon: "error",
          });
        }
      }
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
  },
  mounted() {
    this.models = {
      name: null,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      site: this.site,
      media: [],
      date: new Date(),
      status: "New",
    };
  },
  beforeDestroy() {
    this.closePreview();
  },
};
</script>