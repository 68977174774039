<template>
  <div id="layerForm" class="floating-form" :class="{ show: show }">
    <div
      class="p-3 h5 mb-0 border-bottom border-default-dark bg-dark d-flex justify-content-between align-items-center"
    >
      <small class="font-weight-medium text-default-dark">2D Ortho Layer</small>
      <button class="btn p-0 text-muted" @click="close()">
        <i class="fas fa-times" />
      </button>
    </div>
    <div class="bg-default-dark p-3 position-relative">
      <div class="form-group">
        <label for="layerName">Name<sup class="text-danger">*</sup></label>
        <input
          id="layerName"
          type="text"
          class="form-control"
          v-model="models.name"
        />
      </div>
      <div class="form-group">
        <label for="layerUrl">URL<sup class="text-danger">*</sup></label>
        <input
          id="layerUrl"
          type="text"
          class="form-control"
          v-model="models.url"
        />
      </div>
      <div class="form-group clickable" @click="showCalendarModal = true">
        <label for="layerDate">Date</label>
        <div class="form-control">
          <i class="fas fa-calendar-alt mr-2" />
          {{ models.date }}
        </div>
      </div>
      <div class="bg-dark rounded px-3 py-2 mt-4">
        <span class="badge badge-secondary mb-3">BOUNDS</span>
        <div class="form-group">
          <label for="layerNwBounds">NW</label>
          <input
            id="layerNwBounds"
            type="text"
            class="form-control"
            v-model="models.nw_bounds"
          />
        </div>
        <div class="form-group">
          <label for="layerNeBounds">NE</label>
          <input
            id="layerNeBounds"
            type="text"
            class="form-control"
            v-model="models.ne_bounds"
          />
        </div>
        <div class="form-group">
          <label for="layerSeBounds">SE</label>
          <input
            id="layerSeBounds"
            type="text"
            class="form-control"
            v-model="models.se_bounds"
          />
        </div>
        <div class="form-group">
          <label for="layerSwBounds">SW</label>
          <input
            id="layerSwBounds"
            type="text"
            class="form-control"
            v-model="models.sw_bounds"
          />
        </div>
      </div>
    </div>
    <button class="btn btn-primary btn-block py-2" @click="save()">Save</button>
    <date-picker-modal
      v-if="showCalendarModal"
      :value="models.date"
      @close="closeCalendarModal"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePickerModal from "@/components/DatePickerModal";

export default {
  props: ["show", "site"],
  components: {
    DatePickerModal,
  },
  data() {
    return {
      models: {
        url: null,
        name: null,
        date: null,
        nw_bounds: null,
        ne_bounds: null,
        se_bounds: null,
        sw_bounds: null,
      },
      emptyModels: {
        url: null,
        name: null,
        date: null,
        nw_bounds: null,
        ne_bounds: null,
        se_bounds: null,
        sw_bounds: null,
      },
      errors: [],
      showCalendarModal: false,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show && this.$el) {
          this.$el.scrollTo(0, 0);
        }

        this.models = JSON.parse(JSON.stringify(this.emptyModels));
      },
      immediate: true,
    },
  },
  methods: {
    closeCalendarModal(e) {
      this.showCalendarModal = false;

      if (e !== "close") {
        this.models.date = e;
      }
    },
    async save() {
      this.errors = [];

      if (!this.models.name) {
        this.errors.push("Name is required");
      }

      if (!this.models.url) {
        this.errors.push("URL is required");
      }

      if (this.errors.length > 0) {
        Swal.fire({
          title: "<h5>Error</h5>",
          html: `<div class='line-height-md'>${this.errors.join(
            "<br />"
          )}</div>`,
          icon: "error",
        });
      } else {
        this.$emit("toggle-spinner", true);

        let body = JSON.parse(JSON.stringify(this.models));

        const [call, err] = await this.Helper.handle(
          this.API.post("tiles", body)
        );

        if (!err && call.status == 200) {
          let existingTiles = this.site.tiles.map((tile) => tile._id);

          existingTiles.push(call.data._id);

          const [siteCall, siteErr] = await this.Helper.handle(
            this.API.put(`sites/${this.$route.params.id}`, {
              tiles: existingTiles,
            })
          );

          if (!siteErr && siteCall.status == 200) {
            this.$emit("toggle-spinner", false);

            Swal.fire(
              "<h5 class='mb-0'>2D Ortho layer added</h5>",
              "",
              "success"
            ).then(() => {
              this.close(call.data);
            });
          } else if (siteErr) {
            this.$emit("toggle-spinner", false);

            Swal.fire("<h5 class='mb-0'>Error</h5>", err.message, "error");
          }
        } else if (err) {
          this.$emit("toggle-spinner", false);

          Swal.fire("<h5 class='mb-0'>Error</h5>", err.message, "error");
        }
      }
    },
    close(e) {
      this.$emit("close", e);
    },
  },
};
</script>