<template>
  <div class="dropdown">
    <button
      id="dropdownDatePickerButton"
      type="button"
      class="btn dropdown-toggle"
      :class="btnClass ? btnClass : 'border border-default-dark text-muted'"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span>
        <p class="small text-muted mb-0" v-if="title">{{ title }}</p>
        <i class="far fa-calendar-alt mr-2" v-if="!noIcon" />
        {{ dateFilter || nullText }}
      </span>
    </button>
    <div id="dropdown-menu" class="dropdown-menu px-2" @click.stop>
      <div
        class="dropdown-item-text d-flex justify-content-between align-items-center px-0 pb-2"
      >
        <p
          class="badge badge-dark border border-secondary mb-0 font-weight-normal p-2"
        >
          Select date
        </p>
      </div>
      <v-date-picker
        class="inline-calendar"
        v-model="dateFilter"
        :value="value"
        :model-config="{
          type: 'string',
          mask: 'YYYY-MM-DD',
        }"
        :attributes="[
          {
            dot: 'red',
            dates: [new Date()],
          },
        ]"
        mode="date"
        trim-weeks
        is-dark
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "component-datepicker-dropdown",
  props: ["value", "btn-class", "null-text", "title", "no-icon"],
  data() {
    return {
      dateFilter: null,
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.dateFilter = this.value;
        }
      },
      immediate: true,
    },
    dateFilter: {
      handler() {
        if (this.dateFilter) {
          $("#dropdownDatePickerButton").dropdown("hide");

          this.$emit("date-returned", this.dateFilter);
        }
      },
      immediate: false,
    },
  },
};
</script>