var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn dropdown-toggle",class:_vm.btnClass ? _vm.btnClass : 'border border-default-dark text-muted',attrs:{"id":"dropdownDatePickerButton","type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',[(_vm.title)?_c('p',{staticClass:"small text-muted mb-0"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!_vm.noIcon)?_c('i',{staticClass:"far fa-calendar-alt mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.dateFilter || _vm.nullText)+" ")])]),_c('div',{staticClass:"dropdown-menu px-2",attrs:{"id":"dropdown-menu"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._m(0),_c('v-date-picker',{staticClass:"inline-calendar",attrs:{"value":_vm.value,"model-config":{
        type: 'string',
        mask: 'YYYY-MM-DD',
      },"attributes":[
        {
          dot: 'red',
          dates: [new Date()],
        } ],"mode":"date","trim-weeks":"","is-dark":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-item-text d-flex justify-content-between align-items-center px-0 pb-2"},[_c('p',{staticClass:"badge badge-dark border border-secondary mb-0 font-weight-normal p-2"},[_vm._v(" Select date ")])])}]

export { render, staticRenderFns }