<template>
  <div class="card weather-card" :class="{ compress: !expandWeather }">
    <div class="card-body py-3">
      <div
        class="h5 card-title d-flex justify-content-between align-items-center"
      >
        <small class="font-weight-medium">Weather Data</small>
        <div class="d-flex flex-nowrap">
          <date-picker-dropdown
            :value="date"
            class="mx-1"
            @date-returned="dateSelected"
          />
          <button
            class="btn border border-default-dark text-muted"
            :class="{ 'bg-default-dark': expandWeather }"
            @click="expandWeather = !expandWeather"
          >
            <i class="fas fa-compress-alt" v-if="expandWeather" />
            <i class="fas fa-expand-alt" v-else />
          </button>
        </div>
      </div>
      <div class="row mx-0">
        <div
          class="col weather-item"
          v-for="timing in Object.keys(weather)"
          :key="`weather${timing}`"
        >
          <div class="h-100 py-3">
            <div
              class="
                bg-default-dark
                text-default-dark
                rounded
                p-2
                text-capitalize text-center
                font-weight-bold
              "
            >
              {{ expandWeather ? timing : timing[0] }}
            </div>
            <template v-if="!isLoading">
              <div
                class="
                  weather-info
                  bg-secondary
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  border-bottom border-default-dark
                "
              >
                <div class="d-flex">
                  <img
                    :src="
                      require(`@/assets/icons/${weather[timing].data.icon}.svg`)
                    "
                    v-if="weather[timing].data && weather[timing].data.icon"
                  />
                  <i class="far fa-question-circle text-dark" v-else />
                  <h3
                    v-if="weather[timing].data && weather[timing].data.temp"
                    class="
                      mb-0
                      d-inline
                      font-weight-bold
                      text-dark text-nowrap
                      weather-temp
                    "
                  >
                    {{ weather[timing].data.temp }}
                    &#8451;
                  </h3>
                </div>
                <p
                  class="mb-0 font-weight-medium text-dark mt-3"
                  v-if="weather[timing].data && weather[timing].data.conditions"
                >
                  {{ weather[timing].data.conditions }}
                </p>
              </div>
              <div class="p-3 weather-table table-like">
                <p class="table-item mb-1">
                  <span class="font-weight-medium">Wind</span>
                  <span class="text-right text-default-dark">
                    <img
                      src="@/assets/icons/compass.svg"
                      class="mr-2"
                      :alt="weather[timing].data.winddir"
                      :style="{
                        transform: `rotate(${weather[timing].data.winddir}deg)`,
                        height: '1.5rem',
                        verticalAlign: 'text-bottom',
                      }"
                      v-if="
                        weather[timing].data && weather[timing].data.winddir
                      "
                    />
                    {{
                      weather[timing].data && weather[timing].data.windspeed
                        ? `${weather[timing].data.windspeed} km/h`
                        : "--"
                    }}
                  </span>
                </p>
                <p class="table-item mb-1">
                  <span class="font-weight-medium">Humidity</span>
                  <span class="text-default-dark">
                    {{
                      weather[timing].data && weather[timing].data.humidity
                        ? `${weather[timing].data.humidity}%`
                        : "--"
                    }}
                  </span>
                </p>
                <p class="table-item mb-1">
                  <span class="font-weight-medium">Pressure</span>
                  <span class="text-default-dark">
                    {{
                      weather[timing].data && weather[timing].data.pressure
                        ? `${weather[timing].data.pressure}mb`
                        : "--"
                    }}
                  </span>
                </p>
                <p class="table-item mb-1">
                  <span class="font-weight-medium">Precipitation</span>
                  <span class="text-default-dark">
                    {{
                      weather[timing].data && weather[timing].data.precip
                        ? `${weather[timing].data.precip}mm`
                        : "--"
                    }}
                  </span>
                </p>
              </div>
            </template>
            <div class="weather-spinner text-center" v-else>
              <div class="spinner-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerDropdown from "@/components/DatePickerDropdown";

export default {
  props: ["site"],
  components: {
    DatePickerDropdown,
  },
  data() {
    return {
      isLoading: true,
      date: this.Helper.formatDate(new Date(), "YYYY-MM-DD"),
      weather: {
        morning: {
          time: "08:00:00", // 8am
          data: null,
        },
        afternoon: {
          time: "12:00:00", // 12pm
          data: null,
        },
        evening: {
          time: "18:00:00", // 6pm
          data: null,
        },
        night: {
          time: "23:00:00", // 11pm
          data: null,
        },
      },

      expandWeather: false,
    };
  },
  watch: {
    site: {
      handler() {
        this.mapLayers = [];

        if (this.site && this.site.lat && this.site.lng) {
          this.date = this.Helper.formatDate(new Date(), "YYYY-MM-DD");

          this.getWeatherData();
        } else {
          Object.keys(this.weather).forEach((timing) => {
            this.weather[timing].data = null;
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    dateSelected(e) {
      this.date = e;

      this.getWeatherData();
    },
    async getWeatherData() {
      this.isLoading = true;

      Object.keys(this.weather).forEach((timing) => {
        this.weather[timing].data = null;
      });

      return new Promise((resolve, reject) => {
        let url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${this.site.lat},${this.site.lng}/`;

        if (!this.date) {
          this.date = this.Helper.formatDate(new Date(), "YYYY-MM-DD");
        }

        url += `${this.date}?`;

        const query = {
          key: process.env.VUE_APP_VISUAL_CROSSING_KEY,
          timezone: "Asia/Kuala_Lumpur",
          unitGroup: "metric",
          include: "fcst,days,hours,obs,stats,histfcst",
        };

        Object.keys(query).forEach((key) => {
          url += `${key}=${query[key]}&`;
        });

        return this.API.getExternal(url)
          .then((retVal) => {
            Object.keys(this.weather).forEach((timing) => {
              if (retVal.data.days[0].hours) {
                this.weather[timing].data = retVal.data.days[0].hours.find(
                  (hour) => hour.datetime == this.weather[timing].time
                );
              }
            });
          })
          .finally((_) => {
            this.isLoading = false;

            resolve();
          });
      });
    },
  },
};
</script>