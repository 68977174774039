<template>
  <div class="site-report overflow-auto container-fluid position-relative">
    <button class="btn btn-fab btn-primary shadow-sm" @click="showReportForm()">
      <i class="fas fa-plus" />
    </button>
    <div
      class="table-responsive bg-dark rounded mt-2"
      v-if="reports.length > 0"
    >
      <table class="table table-borderless table-striped mb-0">
        <thead class="thead-dark">
          <tr class="text-default">
            <th style="width: 1%">No</th>
            <th class="text-nowrap">Report Title</th>
            <th class="text-center" style="width: 1%">Date</th>
            <th class="text-center" style="width: 1%"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-default-dark"
            v-for="(report, index) in reports"
            :key="report._id"
          >
            <td style="width: 1%" class="align-middle">{{ index + 1 }}</td>
            <td class="align-middle">{{ report.name }}</td>
            <td class="text-center text-nowrap align-middle">
              {{ Helper.formatDate(report.date, "DD-MM-YYYY") }}
            </td>
            <td style="width: 1%" class="text-center text-nowrap">
              <button
                class="btn btn-sm btn-info text-light mx-1"
                @click="downloadReport(report)"
                title="Download report"
              >
                <i class="fas fa-download" />
              </button>
              <button
                class="btn btn-sm btn-primary text-light mx-1"
                @click="viewReport(report)"
                title="View report"
              >
                <i class="fas fa-eye" />
              </button>
              <button
                class="btn btn-sm btn-danger mx-1"
                @click="deleteReport(report._id)"
                title="Delete report"
              >
                <i class="fas fa-trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bg-dark p-2 shadow-sm rounded" v-else>
      <p class="text-center font-italic mb-0 py-3">No report found</p>
    </div>
    <report-form-modal
      v-if="reportForm.show"
      :site="{
        _id: data ? data._id : null,
        name: data ? data.name : null,
        lat: data ? data.lat : null,
        lng: data ? data.lng : null,
        tiles: data && data.tiles && data.tiles.length > 0 ? data.tiles : [],
      }"
      @toggle-spinner="$emit('toggle-spinner', $event)"
      @close="closeReportForm"
    />
    <media-preview
      v-if="currentReport"
      :title="`${currentReport.name}`"
      :subtitle="data ? data.name : ''"
      :media="
        currentReport.media && currentReport.media.length > 0
          ? currentReport.media[0]
          : null
      "
      @close="currentReport = null"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import MediaPreview from "@/components/MediaPreview";
import ReportFormModal from "@/components/ReportFormModal";

export default {
  name: "component-site-report",
  props: ["data"],
  components: {
    MediaPreview,
    ReportFormModal,
  },
  data() {
    return {
      reports: [],
      reportForm: {
        show: false,
      },
      currentReport: null,
    };
  },
  methods: {
    async downloadReport(report) {
      if (!report || !report.media || !report.media.length) return;

      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.getExternal(
          this.Helper.formatMediaUrl(report.media[0]),
          "file"
        )
      );

      if (err || call.status !== 200) {
        Swal.fire("<h5 class='mb-0'>Download failed</h5>", "", "error");
      } else {
        if (call && call.data) {
          await saveAs(call.data, `${this.data.name} - ${report.name}.pdf`);
        }
      }

      this.$emit("toggle-spinner", false);
    },
    viewReport(report) {
      this.currentReport = report;
    },
    deleteReport(reportId) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this report?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          const [call, err] = await this.Helper.handle(
            this.API.del(`reports/${reportId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire(
              "<h5 class='mb-0'>Report deleted</h5>",
              "",
              "success"
            ).then(() => {
              this.reports = this.reports.filter(
                (report) => report._id !== reportId
              );

              this.$emit("toggle-spinner", false);
            });
          }
        }
      });
    },
    showReportForm(e) {
      this.reportForm = {
        show: true,
      };
    },
    closeReportForm(e) {
      this.reportForm = {
        show: false,
      };

      if (e) {
        this.getReports();
      }
    },
    async getReports() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(`reports?site=${this.$route.params.id}&_sort=date:DESC`)
      );

      if (!err && call.status == 200) {
        this.reports = call.data;
      }

      this.$emit("toggle-spinner", false);
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>