<template>
  <div class="position-relative w-100 h-100">
    <div id="site-map" class="w-100 h-100"></div>
    <div
      id="map-measurement-text"
      class="text-monospace"
      v-if="showTools.measurement"
    >
      <p>Total distance: {{ measurement }} km</p>
    </div>
    <div class="map-floating-button" v-if="showMapButton">
      <div class="d-flex justify-content-end align-items-center">
        <button
          class="btn shadow-sm mr-2"
          :class="
            showTools.layer ? 'btn-success' : 'btn-dark text-default-dark'
          "
          data-toggle="tooltip"
          title="Toggle layer tool"
          :disabled="disableToolButton('layer')"
          @mouseenter="Helper.showTooltip($event)"
          @click="showTools.layer = !showTools.layer"
        >
          <i class="fas fa-layer-group"></i>
        </button>
        <button
          class="btn shadow-sm mr-2"
          :class="
            showTools.standoff || showTools.polygon
              ? 'btn-success'
              : 'btn-dark text-default-dark'
          "
          data-toggle="tooltip"
          title="Toggle annotation tool"
          :disabled="disableToolButton('annotation')"
          @mouseenter="Helper.showTooltip($event)"
          @click="triggerAnnotationPrompt()"
        >
          <i class="fas fa-draw-polygon"></i>
        </button>
        <button
          class="btn shadow-sm mr-2"
          :class="
            showTools.measurement ? 'btn-success' : 'btn-dark text-default-dark'
          "
          data-toggle="tooltip"
          title="Toggle measurement tool"
          :disabled="disableToolButton('measurement')"
          @mouseenter="Helper.showTooltip($event)"
          @click="showTools.measurement = !showTools.measurement"
        >
          <i class="fas fa-ruler-combined"></i>
        </button>
        <button
          class="btn shadow-sm"
          :class="
            isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'
          "
          data-toggle="tooltip"
          title="Toggle satellite view"
          @mouseenter="Helper.showTooltip($event)"
          @click="isSatelliteView = !isSatelliteView"
        >
          <i class="fas fa-satellite"></i>
        </button>
      </div>
      <div class="dropdown mt-2">
        <button
          class="btn btn-dark text-default-dark shadow-sm dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
          type="button"
          id="dropdownLayer"
          data-toggle="dropdown"
        >
          Layers
        </button>
        <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
          <div
            @click.stop
            class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="tileVisibilityToggler"
                v-model="showAllTile"
                @change="toggleTileVisibility('all')"
                v-if="mapLayers.length > 0"
              />
              <label
                class="custom-control-label text-nowrap text-default-dark"
                for="tileVisibilityToggler"
              >
                2D Ortho
              </label>
            </div>
          </div>
          <template v-for="layer in mapLayers">
            <div
              class="dropdown-item-text"
              :key="`layer-${layer.id}`"
              @click.stop
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="layer.id"
                  v-model="layer.show"
                  @change="toggleTileVisibility(layer.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="layer.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ layer.name }}
                    <button
                      class="btn btn-sm btn-link text-danger p-0 mx-2"
                      @click="deleteTile(layer)"
                      title="Delete layer"
                      data-toggle="tooltip"
                      @mouseenter="Helper.showTooltip($event)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                    <template v-if="layer.date">
                      <br /><small class="text-muted">{{
                        Helper.formatDate(layer.date, "YYYY-MM")
                      }}</small>
                    </template>
                  </span>
                  <span v-if="layer.show">{{ layer.opacity }}%</span>
                </label>
              </div>
              <input
                type="range"
                class="custom-range"
                :id="`slider-${layer.id}`"
                step="5"
                v-model="layer.opacity"
                @input="adjustTileVisibility(layer.id)"
                :disabled="!layer.show"
              />
            </div>
          </template>
          <div
            class="dropdown-item-text text-muted text-center small"
            @click.stop
            v-if="mapLayers.length < 1"
          >
            No tile layer found
          </div>
          <div class="dropdown-item-text p-1 bg-dark shadow-inset"></div>
          <div
            @click.stop
            class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="geojsonVisibilityToggler"
                v-model="showAllGeojson"
                @change="toggleGeojsonVisibility('all')"
                v-if="geojsons.length > 0"
              />
              <label
                class="custom-control-label text-nowrap text-default-dark"
                for="geojsonVisibilityToggler"
              >
                GIS Layers
              </label>
            </div>
          </div>
          <template v-for="(folder, folderIndex) in geojsonFolderNames">
            <div
              class="dropdown-item-text text-muted mt-2"
              :key="`geojson-${folderIndex}-folder`"
              @click.stop
            >
              <p class="font-weight-medium line-height-sm mb-0">
                {{ folder }}
              </p>
              <div
                class="custom-control custom-checkbox py-2 ml-3"
                v-for="(geojson, index) in geojsonFolders.filter(
                  (geojson) => geojson.folder == folder
                )"
                :key="`geojson-${index}-folder-file`"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="geojson.id"
                  v-model="geojson.show"
                  @change="toggleGeojsonFolderVisibility(geojson.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="geojson.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ Helper.formatGeojsonName(geojson.name) }}
                    <i
                      class="fas fa-circle ml-1"
                      :style="{ color: geojson.lineColor, fontSize: '70%' }"
                    />
                  </span>
                </label>
              </div>
            </div>
          </template>
          <template v-for="(geojson, index) in geojsons">
            <div
              class="dropdown-item-text text-muted"
              :key="`geojson-${geojson.id}`"
              @click.stop
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="geojson.id"
                  v-model="geojson.show"
                  @change="toggleGeojsonVisibility(geojson.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="geojson.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ Helper.formatGeojsonName(geojson.name) }}
                    <i
                      class="fas fa-circle ml-1"
                      :style="{ color: geojson.lineColor, fontSize: '70%' }"
                    />
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div
            class="dropdown-item-text text-muted text-center small"
            @click.stop
            v-if="geojsons.length < 1 && geojsonFolders.length < 1"
          >
            No GIS layer found
          </div>
        </div>
      </div>
      <div class="dropdown mt-2">
        <button
          class="btn btn-dark text-default-dark shadow-sm dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
          type="button"
          id="dropdownAnnotation"
          data-toggle="dropdown"
        >
          Annotations
        </button>
        <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
          <div
            @click.stop
            class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="polygonVisibilityToggler"
                v-model="showAllPolygon"
                @change="togglePolygonVisibility('all')"
                v-if="polygons.length > 0"
              />
              <label
                class="custom-control-label text-nowrap text-default-dark"
                for="polygonVisibilityToggler"
              >
                Show All
              </label>
            </div>
          </div>
          <template v-for="polygon in polygons">
            <div
              class="dropdown-item-text"
              :key="`polygon-${polygon.id}`"
              @click.stop
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="polygon.id"
                  v-model="polygon.show"
                  @change="togglePolygonVisibility(polygon.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="polygon.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ polygon.name }}<br />
                    <small>{{ polygon.date }}</small>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div
            class="dropdown-item-text text-muted text-center small"
            @click.stop
            v-if="polygons.length < 1"
          >
            No annotation found
          </div>
        </div>
      </div>
      <div class="dropdown mt-2">
        <button
          class="btn btn-dark text-default-dark shadow-sm dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
          type="button"
          id="dropdownStandoff"
          data-toggle="dropdown"
        >
          Standoff / Drone
        </button>
        <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
          <div
            @click.stop
            class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="standoffVisibilityToggler"
                v-model="showAllStandoff"
                @change="toggleStandoffVisibility('all')"
                v-if="standoffShots.length > 0"
              />
              <label
                class="custom-control-label text-nowrap text-default-dark"
                for="standoffVisibilityToggler"
              >
                Show All
              </label>
            </div>
          </div>
          <template v-for="standoff in standoffShots">
            <div
              class="dropdown-item-text"
              :key="`standoff-${standoff.id}`"
              @click.stop
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="standoff.id"
                  v-model="standoff.show"
                  @change="toggleStandoffVisibility(standoff.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="standoff.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ standoff.name }}<br />
                    <small>{{ standoff.date }}</small>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div
            class="dropdown-item-text text-muted text-center small"
            @click.stop
            v-if="standoffShots.length < 1"
          >
            No shot found
          </div>
        </div>
      </div>
      <div class="dropdown mt-2">
        <button
          class="btn btn-dark text-default-dark shadow-sm dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
          type="button"
          id="dropdownPin"
          data-toggle="dropdown"
        >
          Pin
        </button>
        <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
          <div
            @click.stop
            class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="pinVisibilityToggler"
                v-model="showAllPin"
                @change="togglePinVisibility('all')"
                v-if="pinShots.length > 0"
              />
              <label
                class="custom-control-label text-nowrap text-default-dark"
                for="pinVisibilityToggler"
              >
                Show All
              </label>
            </div>
          </div>
          <template v-for="pin in pinShots">
            <div class="dropdown-item-text" :key="`pin-${pin.id}`" @click.stop>
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="pin.id"
                  v-model="pin.show"
                  @change="togglePinVisibility(pin.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="pin.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ pin.name }}<br />
                    <small>{{ pin.date }}</small>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div
            class="dropdown-item-text text-muted text-center small"
            @click.stop
            v-if="pinShots.length < 1"
          >
            No pin found
          </div>
        </div>
      </div>
    </div>
    <standoff-form
      :show="showTools.standoff"
      :mouseCoordinate="mouseClickCoordinate"
      @close="closeAnnotationTool"
      @toggle-spinner="toggleSpinner"
      @rotate="rotateStandoff"
    />
    <polygon-form
      :show="showTools.polygon"
      :features="polygonFeatures"
      @close="closeAnnotationTool"
      @toggle-spinner="toggleSpinner"
    />
    <pin-form
      :show="showTools.pin"
      :mouseCoordinate="mouseClickCoordinate"
      @close="closeAnnotationTool"
      @toggle-spinner="toggleSpinner"
    />
    <layer-form
      :show="showTools.layer"
      :site="data"
      @close="closeLayerTool"
      @toggle-spinner="toggleSpinner"
    />
    <media-preview
      v-if="currentMedia"
      :title="
        currentMedia.title ? currentMedia.title : `${data.name} Standoff Shot`
      "
      :subtitle="currentMedia.subtitle ? currentMedia.subtitle : null"
      :media="currentMedia.data ? currentMedia.data : currentMedia"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import Swal from "sweetalert2";
import MediaPreview from "@/components/MediaPreview";
import StandoffForm from "@/components/StandoffForm";
import StandoffPopup from "@/components/StandoffPopup";
import PolygonForm from "@/components/PolygonForm";
import PolygonPopup from "@/components/PolygonPopup";
import PinForm from "@/components/PinForm";
import PinPopup from "@/components/PinPopup";
import LayerForm from "@/components/LayerForm";
import Vue from "vue";

export default {
  name: "component-site-orthomosaic",
  props: ["data"],
  components: {
    StandoffForm,
    PolygonForm,
    PinForm,
    LayerForm,
    MediaPreview,
  },
  data() {
    return {
      map: null,
      measurement: 0,
      showMapButton: false,
      showTools: {
        measurement: false,
        standoff: false,
        polygon: false,
        layer: false,
        pin: false,
      },
      isSatelliteView: false,
      measurementGeoJson: {
        type: "FeatureCollection",
        features: [],
      },
      measurementLineString: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      },
      mapLayers: [],
      geojsons: [],
      geojsonFolders: [],
      geojsonFolderNames: [],
      showAllGeojson: false,
      showAllGeojsonFolder: false,
      showAllTile: true,
      currentMedia: null,
      mouseClickCoordinate: {},
      standoffShots: [],
      showAllStandoff: true,
      standoffMarker: null,
      polygonDrawing: null,
      polygonFeatures: null,
      polygons: [],
      showAllPolygon: true,
      pinShots: [],
      showAllPin: true,
      pinMarker: null,
    };
  },
  computed: {
    disableToolButton() {
      return (type) => {
        let tools = JSON.parse(JSON.stringify(this.showTools));

        delete tools[type];

        return Object.values(tools).some((tool) => tool);
      };
    },
  },
  watch: {
    "showTools.measurement": {
      handler() {
        if (!this.showTools.measurement) {
          this.removeMeasurement();
        } else {
          this.drawMeasurement();
        }
      },
      immediate: true,
    },
    isSatelliteView: {
      handler() {
        this.showTools.measurement = false;

        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);
        }
      },
      immediate: false,
    },
    data: {
      handler() {
        if (this.data) {
          this.getStandoffShots();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    rotateStandoff(e) {
      if (this.standoffMarker) {
        this.standoffMarker.setRotation(e);
      }
    },
    addPolygonToMap(polygon) {
      this.map.addSource(`polygonSource-${polygon._id}`, {
        type: "geojson",
        data: polygon.features,
      });

      this.map.addLayer({
        id: `polygonLayerFill-${polygon._id}`,
        type: "fill",
        source: `polygonSource-${polygon._id}`,
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });

      this.map.addLayer({
        id: `polygonLayerOutline-${polygon._id}`,
        type: "line",
        source: `polygonSource-${polygon._id}`,
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });

      this.map.on("click", `polygonLayerFill-${polygon._id}`, (e) => {
        this.showPolygonPopup(e, polygon);
      });

      this.map.on("mouseenter", `polygonLayerFill-${polygon._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "pointer";
        }
      });

      this.map.on("mouseleave", `polygonLayerFill-${polygon._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "";
        }
      });
    },
    showPolygonPopup(e, polygon) {
      const PolygonPopupClass = Vue.extend(PolygonPopup);

      const popup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(`<div id="polygon-popup-content-${polygon._id}"></div>`)
        .addTo(this.map);

      const vm = this;

      const popupInstance = new PolygonPopupClass({
        propsData: {
          data: polygon,
        },
        methods: {
          deletePolygon(e) {
            if (e) {
              vm.deletePolygon(e, popup);
            }
          },
        },
      });

      popupInstance.$mount(`#polygon-popup-content-${polygon._id}`);

      popup._update();
    },
    deletePolygon(polygonId, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this annotation?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          popup.remove();

          const [call, err] = await this.Helper.handle(
            this.API.del(`site-polygons/${polygonId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire(
              "<h5 class='mb-0'>Annotation deleted</h5>",
              "",
              "success"
            ).then(() => {
              const layers = this.map
                .getStyle()
                .layers.filter(
                  (layer) =>
                    layer.id == `polygonLayerFill-${polygonId}` ||
                    layer.id == `polygonLayerOutline-${polygonId}`
                );

              const sources = Object.keys(this.map.getStyle().sources).filter(
                (source) => source == `polygonSource-${polygonId}`
              );

              if (layers && layers.length > 0) {
                layers.forEach((layer) => {
                  this.map.removeLayer(layer.id);
                });
              }

              if (sources && sources.length > 0) {
                sources.forEach((source) => {
                  this.map.removeSource(source);
                });
              }

              this.polygons = this.polygons.filter(
                (polygon) => polygon.id !== `polygonLayer:type-${polygonId}`
              );

              this.$emit("toggle-spinner", false);
            });
          }
        }
      });
    },
    addPinShotToMap(shot) {
      this.map.addSource(`pinSource-${shot._id}`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [shot.lng, shot.lat],
              },
            },
          ],
        },
      });

      this.map.addLayer({
        id: `pinLayer-${shot._id}`,
        type: "symbol",
        source: `pinSource-${shot._id}`,
        layout: {
          "icon-image": shot.isVideo ? "pin-video" : "pin-360",
          "icon-size": 1,
          "icon-allow-overlap": true,
        },
      });

      this.map.on("click", `pinLayer-${shot._id}`, (e) => {
        if (!this.showTools.standoff) {
          const PinPopupClass = Vue.extend(PinPopup);

          const popup = new mapboxgl.Popup()
            .setLngLat([shot.lng, shot.lat])
            .setHTML(`<div id="pin-popup-content-${shot._id}"></div>`)
            .addTo(this.map);

          const vm = this;

          const popupInstance = new PinPopupClass({
            propsData: {
              data: shot,
            },
            methods: {
              viewImage(e) {
                if (e) {
                  vm.currentMedia = {
                    title: shot.name,
                    subtitle: shot.date,
                    data: e,
                  };
                }
              },
              deletePin(e) {
                if (e) {
                  vm.deletePinShot(e, popup);
                }
              },
              clonePin(lat, lng) {
                vm.clonePinShot(lat, lng, popup);
              },
            },
          });

          popupInstance.$mount(`#pin-popup-content-${shot._id}`);

          popup._update();
        }
      });

      this.map.on("mouseenter", `pinLayer-${shot._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "pointer";

          this.changeIconSize(`pinLayer-${shot._id}`, true);
        }
      });

      this.map.on("mouseleave", `pinLayer-${shot._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "";

          this.changeIconSize(`pinLayer-${shot._id}`, false);
        }
      });
    },
    clonePinShot(lat, lng, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Clone this pin?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          popup.remove();

          if (lat && lng) {
            this.showTools.pin = true;

            this.pinMouseClickEvent(
              { lngLat: { lat: lat, lng: lng } },
              "nodrag"
            );

            this.mouseClickCoordinate.isClone = true;
          }
        }
      });
    },
    deletePinShot(shotId, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this pin?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          popup.remove();

          const [call, err] = await this.Helper.handle(
            this.API.del(`site-pins/${shotId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire("<h5 class='mb-0'>Pin deleted</h5>", "", "success").then(
              () => {
                const layers = this.map
                  .getStyle()
                  .layers.filter((layer) => layer.id == `pinLayer-${shotId}`);

                const sources = Object.keys(this.map.getStyle().sources).filter(
                  (source) => source == `pinSource-${shotId}`
                );

                if (layers && layers.length > 0) {
                  layers.forEach((layer) => {
                    this.map.removeLayer(layer.id);
                  });
                }

                if (sources && sources.length > 0) {
                  sources.forEach((source) => {
                    this.map.removeSource(source);
                  });
                }

                this.pinShots = this.pinShots.filter(
                  (shot) => shot._id != shotId
                );

                this.$emit("toggle-spinner", false);
              }
            );
          }
        }
      });
    },
    async getPinShots() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(`site-pins?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.pinShots = call.data.map((data) => {
          data.id = `pinLayer-${data._id}`;
          data.show = true;

          return data;
        });
      }

      this.$nextTick(() => {
        this.initMap();
      });
    },
    addStandoffShotToMap(shot) {
      this.map.addSource(`standoffSource-${shot._id}`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [shot.lng, shot.lat],
              },
            },
          ],
        },
      });

      this.map.addLayer({
        id: `standoffLayer-${shot._id}`,
        type: "symbol",
        source: `standoffSource-${shot._id}`,
        layout: {
          "icon-image": "drone",
          "icon-size": 1,
          "icon-rotate": shot.bearing || 0,
          "icon-allow-overlap": true,
        },
      });

      this.map.on("click", `standoffLayer-${shot._id}`, (e) => {
        if (!this.showTools.standoff) {
          const StandoffPopupClass = Vue.extend(StandoffPopup);

          const popup = new mapboxgl.Popup()
            .setLngLat([shot.lng, shot.lat])
            .setHTML(`<div id="standoff-popup-content-${shot._id}"></div>`)
            .addTo(this.map);

          const vm = this;

          const popupInstance = new StandoffPopupClass({
            propsData: {
              data: shot,
            },
            methods: {
              viewImage(e) {
                if (e) {
                  vm.currentMedia = {
                    title: shot.name,
                    subtitle: shot.date,
                    data: e,
                  };
                }
              },
              deleteShot(e) {
                if (e) {
                  vm.deleteStandoffShot(e, popup);
                }
              },
              cloneShot(lat, lng, bearing) {
                vm.cloneStandoffShot(lat, lng, bearing, popup);
              },
            },
          });

          popupInstance.$mount(`#standoff-popup-content-${shot._id}`);

          popup._update();
        }
      });

      this.map.on("mouseenter", `standoffLayer-${shot._id}`, (e) => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "pointer";

          this.changeIconSize(`standoffLayer-${shot._id}`, true);
        }
      });

      this.map.on("mouseleave", `standoffLayer-${shot._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon &&
          !this.showTools.pin
        ) {
          this.map.getCanvas().style.cursor = "";

          this.changeIconSize(`standoffLayer-${shot._id}`, false);
        }
      });
    },
    changeIconSize(id, enlarge) {
      this.map.setLayoutProperty(id, "icon-size", enlarge ? 1.2 : 1);
    },
    cloneStandoffShot(lat, lng, bearing, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Clone this shot?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          popup.remove();

          if (lat && lng) {
            this.showTools.standoff = true;

            this.standoffMouseClickEvent(
              { lngLat: { lat: lat, lng: lng } },
              "nodrag"
            );

            this.mouseClickCoordinate.bearing = bearing;
            this.mouseClickCoordinate.isClone = true;

            this.rotateStandoff(bearing);
          }
        }
      });
    },
    deleteStandoffShot(shotId, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this shot?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          popup.remove();

          const [call, err] = await this.Helper.handle(
            this.API.del(`site-standoffs/${shotId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire("<h5 class='mb-0'>Shot deleted</h5>", "", "success").then(
              () => {
                const layers = this.map
                  .getStyle()
                  .layers.filter(
                    (layer) => layer.id == `standoffLayer-${shotId}`
                  );

                const sources = Object.keys(this.map.getStyle().sources).filter(
                  (source) => source == `standoffSource-${shotId}`
                );

                if (layers && layers.length > 0) {
                  layers.forEach((layer) => {
                    this.map.removeLayer(layer.id);
                  });
                }

                if (sources && sources.length > 0) {
                  sources.forEach((source) => {
                    this.map.removeSource(source);
                  });
                }

                this.standoffShots = this.standoffShots.filter(
                  (shot) => shot._id != shotId
                );

                this.$emit("toggle-spinner", false);
              }
            );
          }
        }
      });
    },
    async getStandoffShots() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(`site-standoffs?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.standoffShots = call.data.map((data) => {
          data.id = `standoffLayer-${data._id}`;
          data.show = true;

          return data;
        });
      }

      this.getPolygons();
    },
    async getPolygons() {
      const [call, err] = await this.Helper.handle(
        this.API.get(`site-polygons?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.showAllPolygon = true;

        this.polygons = call.data.map((data) => {
          data.id = `polygonLayer:type-${data._id}`;
          data.show = true;

          return data;
        });
      }

      this.getPinShots();
    },
    toggleSpinner(e) {
      this.$emit("toggle-spinner", e);
    },
    closeAnnotationTool(e) {
      if (this.showTools.standoff) {
        this.showTools.standoff = false;

        this.mouseClickCoordinate = {};

        this.map.off("mousemove", this.mapCursorCrosshairEvent);

        this.map.off("click", this.standoffMouseClickEvent);

        if (this.standoffMarker) {
          this.standoffMarker.off("dragend", this.standoffMarkerDragEndEvent);

          this.standoffMarker.remove();

          this.standoffMarker = null;
        }

        if (e) {
          e.id = `standoffLayer-${e._id}`;

          e.show = true;

          this.standoffShots.push(e);

          this.addStandoffShotToMap(e);
        }
      } else if (this.showTools.polygon) {
        this.showTools.polygon = false;

        if (this.polygonDrawing) {
          this.map.off("draw.update", this.updatePolygonFeatures);

          this.map.off("draw.create", this.updatePolygonFeatures);

          this.map.removeControl(this.polygonDrawing);

          this.polygonDrawing = null;

          this.polygonFeatures = null;
        }

        if (e) {
          e.id = `polygonLayer:type-${e._id}`;

          e.show = true;

          this.polygons.push(e);

          this.addPolygonToMap(e);
        }
      } else if (this.showTools.pin) {
        this.showTools.pin = false;

        this.mouseClickCoordinate = {};

        this.map.off("mousemove", this.mapCursorCrosshairEvent);

        this.map.off("click", this.pinMouseClickEvent);

        if (this.pinMarker) {
          this.pinMarker.off("dragend", this.pinMarkerDragEndEvent);

          this.pinMarker.remove();

          this.pinMarker = null;
        }

        if (e) {
          e.id = `pinLayer-${e._id}`;

          e.show = true;

          this.pinShots.push(e);

          this.addPinShotToMap(e);
        }
      }
    },
    closeLayerTool(e) {
      this.showTools.layer = false;

      if (e) {
        this.map.addSource(`tile-${this.mapLayers.length}`, {
          type: "raster",
          tiles: [`${e.url}{z}/{x}/{y}.png`],
          tileSize: 256,
          attribution:
            'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
        });

        this.map.addLayer({
          id: `tile-${this.mapLayers.length}`,
          type: "raster",
          source: `tile-${this.mapLayers.length}`,
          layout: {},
        });

        this.mapLayers.push({
          id: `tile-${this.mapLayers.length}`,
          name: e.name,
          date: e.date,
          show: this.showAllTile,
          opacity: 100,
        });

        this.$emit("update-site-data", {
          field: "tiles",
          type: "push",
          data: e,
        });
      }
    },
    mapCursorCrosshairEvent() {
      this.map.getCanvas().style.cursor = "crosshair";
    },
    mapCursorDefaultEvent() {
      this.map.getCanvas().style.cursor = "";
    },
    triggerAnnotationPrompt() {
      if (
        this.showTools.standoff ||
        this.showTools.polygon ||
        this.showTools.pin
      ) {
        this.closeAnnotationTool();
      } else {
        Swal.fire({
          icon: "warning",
          title: "<h5 class='mb-0'>Select annotation type</h5>",
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: "Pin",
          denyButtonText: "Standoff",
          confirmButtonText: "Polygon",
        }).then((result) => {
          if (result.isConfirmed) {
            // Show polygon tool
            this.showTools.polygon = true;

            this.polygonDrawing = new MapboxDraw({
              displayControlsDefault: false,
              controls: {
                polygon: false,
                trash: false,
              },
              defaultMode: "draw_polygon",
            });

            this.map.addControl(this.polygonDrawing, "bottom-right");

            this.map.on("draw.update", this.updatePolygonFeatures);

            this.map.on("draw.create", this.updatePolygonFeatures);
          } else if (result.isDenied) {
            // Show standoff tool
            this.showTools.standoff = true;

            this.map.on("mousemove", this.mapCursorCrosshairEvent);

            this.map.on("click", this.standoffMouseClickEvent);
          } else if (result.isDismissed && result.dismiss == "cancel") {
            // Show pin tool
            this.showTools.pin = true;

            this.map.on("mousemove", this.mapCursorCrosshairEvent);

            this.map.on("click", this.pinMouseClickEvent);
          }
        });
      }
    },
    updatePolygonFeatures() {
      this.polygonFeatures = this.polygonDrawing.getAll();
    },
    standoffMouseClickEvent(e, type) {
      this.mouseClickCoordinate = e.lngLat;

      if (this.standoffMarker) {
        this.standoffMarker.setLngLat(e.lngLat);
      } else {
        const el = document.createElement("div");

        el.className = "standoff-marker";
        el.style.backgroundImage = `linear-gradient(rgba(255, 244, 0, 0.5), rgba(255, 244, 0, 0.5)), url(${require("@/assets/icons/drone.png")}`;
        el.style.width = "30px";
        el.style.height = "30px";
        el.style.backgroundSize = "100%";
        el.style.borderRadius = "50%";

        this.standoffMarker = new mapboxgl.Marker({
          element: el,
          draggable: type == "nodrag" ? false : true,
        })
          .setLngLat(e.lngLat)
          .addTo(this.map);

        if (type !== "nodrag") {
          this.standoffMarker.on("dragend", this.standoffMarkerDragEndEvent);
        }
      }
    },
    standoffMarkerDragEndEvent() {
      const lngLat = this.standoffMarker.getLngLat();

      this.mouseClickCoordinate = lngLat;
    },
    pinMouseClickEvent(e, type) {
      this.mouseClickCoordinate = e.lngLat;

      if (this.pinMarker) {
        this.pinMarker.setLngLat(e.lngLat);
      } else {
        const el = document.createElement("div");

        el.className = "pin-marker";
        // el.style.backgroundImage = `linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 244, 255, 0.2)), url(${require("@/assets/icons/pin.png")}`;
        el.style.backgroundImage = `url(${require("@/assets/icons/pin.png")}`;
        el.style.width = "30px";
        el.style.height = "30px";
        el.style.backgroundSize = "100%";
        el.style.borderRadius = "50%";

        this.pinMarker = new mapboxgl.Marker({
          element: el,
          draggable: type == "nodrag" ? false : true,
        })
          .setLngLat(e.lngLat)
          .addTo(this.map);

        if (type !== "nodrag") {
          this.pinMarker.on("dragend", this.pinMarkerDragEndEvent);
        }
      }
    },
    pinMarkerDragEndEvent() {
      const lngLat = this.pinMarker.getLngLat();

      this.mouseClickCoordinate = lngLat;
    },
    togglePolygonVisibility(polygonId) {
      if (polygonId == "all") {
        this.polygons.forEach((polygon) => {
          this.adjustPolygonVisibility(polygon.id, "all");

          polygon.show = this.showAllPolygon;
        });
      } else {
        const polygon = this.polygons.find(
          (polygon) => polygon.id == polygonId
        );

        if (polygon) {
          this.adjustPolygonVisibility(polygon.id);
        }
      }
    },
    adjustPolygonVisibility(polygonId, type) {
      let visibility = this.showAllPolygon ? "visible" : "none";

      if (type != "all") {
        const polygon = this.polygons.find(
          (polygon) => polygon.id == polygonId
        );

        if (polygon) {
          visibility = polygon.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (this.polygons.filter((p) => p.show).length < this.polygons.length) {
          this.showAllPolygon = false;
        } else if (
          this.polygons.filter((p) => p.show).length == this.polygons.length
        ) {
          this.showAllPolygon = true;
        }
      }

      if (visibility !== null) {
        this.map.setLayoutProperty(
          polygonId.replace(":type", "Fill"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          polygonId.replace(":type", "Outline"),
          "visibility",
          visibility
        );
      }
    },
    toggleStandoffVisibility(standoffId) {
      if (standoffId == "all") {
        this.standoffShots.forEach((standoff) => {
          this.adjustStandoffVisibility(standoff.id, "all");

          standoff.show = this.showAllStandoff;
        });
      } else {
        const standoff = this.standoffShots.find(
          (standoff) => standoff.id == standoffId
        );

        if (standoff) {
          this.adjustStandoffVisibility(standoff.id);
        }
      }
    },
    adjustStandoffVisibility(standoffId, type) {
      let visibility = this.showAllStandoff ? "visible" : "none";

      if (type != "all") {
        const standoff = this.standoffShots.find(
          (standoff) => standoff.id == standoffId
        );

        if (standoff) {
          visibility = standoff.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (
          this.standoffShots.filter((s) => s.show).length <
          this.standoffShots.length
        ) {
          this.showAllStandoff = false;
        } else if (
          this.standoffShots.filter((s) => s.show).length ==
          this.standoffShots.length
        ) {
          this.showAllStandoff = true;
        }
      }

      if (visibility !== null) {
        if (
          this.map.getStyle().layers.find((layer) => layer.id == standoffId)
        ) {
          this.map.setLayoutProperty(standoffId, "visibility", visibility);
        }
      }
    },
    togglePinVisibility(pinId) {
      if (pinId == "all") {
        this.pinShots.forEach((pin) => {
          this.adjustPinVisibility(pin.id, "all");

          pin.show = this.showAllPin;
        });
      } else {
        const pin = this.pinShots.find((pin) => pin.id == pinId);

        if (pin) {
          this.adjustPinVisibility(pin.id);
        }
      }
    },
    adjustPinVisibility(pinId, type) {
      let visibility = this.showAllPin ? "visible" : "none";

      if (type != "all") {
        const pin = this.pinShots.find((pin) => pin.id == pinId);

        if (pin) {
          visibility = pin.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (this.pinShots.filter((s) => s.show).length < this.pinShots.length) {
          this.showAllPin = false;
        } else if (
          this.pinShots.filter((s) => s.show).length == this.pinShots.length
        ) {
          this.showAllPin = true;
        }
      }

      if (visibility !== null) {
        if (this.map.getStyle().layers.find((layer) => layer.id == pinId)) {
          this.map.setLayoutProperty(pinId, "visibility", visibility);
        }
      }
    },
    toggleGeojsonVisibility(geojsonId) {
      if (geojsonId == "all") {
        this.geojsons.forEach((geojson) => {
          this.adjustGeojsonVisibility(geojson.id, "all");

          geojson.show = this.showAllGeojson;
        });
      } else {
        const geojson = this.geojsons.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          this.adjustGeojsonVisibility(geojson.id);
        }
      }
    },
    toggleGeojsonFolderVisibility(geojsonId) {
      if (geojsonId == "all") {
        this.geojsonFolders.forEach((geojson) => {
          this.adjustGeojsonFolderVisibility(geojson.id, "all");

          geojson.show = this.showAllGeojsonFolder;
        });
      } else {
        const geojson = this.geojsonFolders.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          this.adjustGeojsonFolderVisibility(geojson.id);
        }
      }
    },
    adjustGeojsonVisibility(geojsonId, type) {
      let visibility = this.showAllGeojson ? "visible" : "none";

      if (type != "all") {
        const geojson = this.geojsons.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          visibility = geojson.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (this.geojsons.filter((g) => g.show).length < this.geojsons.length) {
          this.showAllGeojson = false;
        } else if (
          this.geojsons.filter((g) => g.show).length == this.geojsons.length
        ) {
          this.showAllGeojson = true;
        }
      }

      if (visibility !== null) {
        this.map.setLayoutProperty(
          geojsonId.replace(":type", "fill"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "line"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "circle"),
          "visibility",
          visibility
        );
      }
    },
    adjustGeojsonFolderVisibility(geojsonId, type) {
      let visibility = this.showAllGeojsonFolder ? "visible" : "none";

      if (type != "all") {
        const geojson = this.geojsonFolders.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          visibility = geojson.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (
          this.geojsonFolders.filter((g) => g.show).length <
          this.geojsonFolders.length
        ) {
          this.showAllGeojsonFolder = false;
        } else if (
          this.geojsonFolders.filter((g) => g.show).length ==
          this.geojsonFolders.length
        ) {
          this.showAllGeojsonFolder = true;
        }
      }

      if (visibility !== null) {
        this.map.setLayoutProperty(
          geojsonId.replace(":type", "fill"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "line"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "circle"),
          "visibility",
          visibility
        );
      }
    },
    toggleTileVisibility(layerId) {
      if (layerId == "all") {
        this.mapLayers.forEach((layer) => {
          layer.opacity = !this.showAllTile ? 0 : 100;

          layer.show = this.showAllTile;

          this.adjustTileVisibility(layer.id, "all");
        });
      } else {
        const layer = this.mapLayers.find((layer) => layer.id == layerId);

        if (layer) {
          layer.opacity = !layer.show ? 0 : 100;

          this.adjustTileVisibility(layerId);
        }
      }
    },
    adjustTileVisibility(layerId, type) {
      const layer = this.mapLayers.find((layer) => layer.id == layerId);

      let visibility = this.showAllTile ? "visible" : "none";

      if (type != "all") {
        if (layer) {
          visibility = layer.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (
          this.mapLayers.filter((m) => m.show).length < this.mapLayers.length
        ) {
          this.showAllTile = false;
        } else if (
          this.mapLayers.filter((m) => m.show).length == this.mapLayers.length
        ) {
          this.showAllTile = true;
        }
      }

      if (visibility !== null) {
        const opacity = parseInt(layer.opacity, 10) / 100;

        this.map.setPaintProperty(layerId, "raster-opacity", opacity);
      }
    },
    measurementMouseMoveEvent(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // UI indicator for clicking/hovering a point on the map
      this.map.getCanvas().style.cursor = features.length
        ? "pointer"
        : "crosshair";
    },
    measurementMouseClickEvent(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // Remove the linestring from the group
      // So we can redraw it based on the points collection
      if (this.measurementGeoJson.features.length > 1) {
        this.measurementGeoJson.features.pop();
      }

      // Clear the Distance container to populate it with a new value
      this.measurement = 0;

      // If a feature was clicked, remove it from the map
      if (features.length) {
        let id = features[0].properties.id;

        this.measurementGeoJson.features =
          this.measurementGeoJson.features.filter(function (point) {
            return point.properties.id !== id;
          });
      } else {
        let point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        this.measurementGeoJson.features.push(point);
      }

      if (this.measurementGeoJson.features.length > 1) {
        this.measurementLineString.geometry.coordinates =
          this.measurementGeoJson.features.map(function (point) {
            return point.geometry.coordinates;
          });

        this.measurementGeoJson.features.push(this.measurementLineString);

        // // Populate the distanceContainer with total distance
        this.measurement = turf
          .length(this.measurementLineString)
          .toLocaleString();
      }

      this.map.getSource("measurementGeoJson").setData(this.measurementGeoJson);
    },
    removeMeasurement() {
      this.measurement = 0;

      this.measurementGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      this.measurementLineString = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };

      if (this.map) {
        this.map.removeLayer("measure-lines");
        this.map.removeLayer("measure-points");
        this.map.removeSource("measurementGeoJson");

        this.map.getCanvas().style.cursor = "";

        this.map.off("mousemove", this.measurementMouseMoveEvent);
        this.map.off("click", this.measurementMouseClickEvent);
      }
    },
    drawMeasurement() {
      // GeoJSON object to hold our measurement features
      this.measurementGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      // Used to draw a line between points
      this.measurementLineString = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };

      this.map.addSource("measurementGeoJson", {
        type: "geojson",
        data: this.measurementGeoJson,
      });

      // Add styles to the map
      this.map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "measurementGeoJson",
        paint: {
          "circle-radius": 5,
          "circle-color": "#000",
        },
        filter: ["in", "$type", "Point"],
      });

      this.map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "measurementGeoJson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });

      this.map.on("click", this.measurementMouseClickEvent);

      this.map.on("mousemove", this.measurementMouseMoveEvent);
    },
    deleteTile(tile) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this layer?</h5>",
        html: `<p class="mb-0 text-default-dark">${tile.name}</p>`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          const [call, err] = await this.Helper.handle(
            this.API.del(`tiles/${tile.tileId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire(
              "<h5 class='mb-0'>Ortho deleted</h5>",
              "",
              "success"
            ).then(() => {
              const layers = this.map
                .getStyle()
                .layers.filter((layer) => layer.id == tile.id);

              const sources = Object.keys(this.map.getStyle().sources).filter(
                (source) => source == tile.id
              );

              if (layers && layers.length > 0) {
                layers.forEach((layer) => {
                  this.map.removeLayer(layer.id);
                });
              }

              if (sources && sources.length > 0) {
                sources.forEach((source) => {
                  this.map.removeSource(source);
                });
              }

              this.mapLayers = this.mapLayers.filter(
                (tile) => tile.id != tile.id
              );

              this.$emit("toggle-spinner", false);
            });
          }
        }
      });
    },
    addMapLayers() {
      const layers = this.map
        .getStyle()
        .layers.filter(
          (layer) =>
            layer.id == "boundaryLayer" ||
            layer.id.includes("tile-") ||
            layer.id.includes("geojsonLayer-") ||
            layer.id.includes("geojsonFolderLayer-") ||
            layer.id.includes("standoffLayer-") ||
            layer.id.includes("polygonLayer") ||
            layer.id.includes("pinLayer-")
        );

      const sources = Object.keys(this.map.getStyle().sources).filter(
        (source) =>
          source == "boundarySource" ||
          source.includes("tile-") ||
          source.includes("geojsonSource-") ||
          source.includes("geojsonFolderSource-") ||
          source.includes("standoffSource-") ||
          source.includes("polygonSource-") ||
          source.includes("pinSource-")
      );

      if (layers && layers.length > 0) {
        layers.forEach((layer) => {
          this.map.removeLayer(layer.id);
        });
      }

      if (sources && sources.length > 0) {
        sources.forEach((source) => {
          this.map.removeSource(source);
        });
      }

      this.mapLayers = [];

      // Add tiles
      if (this.data.tiles && this.data.tiles.length > 0) {
        let bounds = new mapboxgl.LngLatBounds();

        this.data.tiles.forEach((tile, index) => {
          if (tile.nw_bounds) {
            bounds.extend(tile.nw_bounds.split(","));
          }

          if (tile.ne_bounds) {
            bounds.extend(tile.ne_bounds.split(","));
          }

          if (tile.se_bounds) {
            bounds.extend(tile.se_bounds.split(","));
          }

          if (tile.sw_bounds) {
            bounds.extend(tile.sw_bounds.split(","));
          }

          this.map.addSource(`tile-${index}`, {
            type: "raster",
            tiles: [`${tile.url}{z}/{x}/{y}.png`],
            tileSize: 256,
            attribution:
              'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
          });

          this.map.addLayer({
            id: `tile-${index}`,
            type: "raster",
            source: `tile-${index}`,
            minzoom: 0,
            maxzoom: 22,
          });

          this.mapLayers.push({
            id: `tile-${index}`,
            tileId: tile._id,
            name: tile.name,
            date: tile.date,
            show: true,
            opacity: 100,
          });
        });

        if (Object.keys(bounds).length > 0) {
          this.map.fitBounds(bounds, {
            padding: 20,
            duration: 0,
          });
        }
      }

      // Add boundary
      if (this.data.boundary) {
        this.map.addSource("boundarySource", {
          type: "geojson",
          data: this.data.boundary.url,
        });

        this.map.addLayer({
          id: "boundaryLayer",
          type: "line",
          source: "boundarySource",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "stroke"],
            "line-width": 4,
          },
        });

        this.map.on("click", "boundaryLayer", (e) => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon &&
            !this.showTools.pin
          ) {
            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(this.map);
          }
        });

        this.map.on("mouseenter", "boundaryLayer", () => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon &&
            !this.showTools.pin
          ) {
            this.map.getCanvas().style.cursor = "pointer";
          }
        });

        this.map.on("mouseleave", "boundaryLayer", () => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon &&
            !this.showTools.pin
          ) {
            this.map.getCanvas().style.cursor = "";
          }
        });
      }

      // Add geojson
      this.geojsons = [];

      if (this.data.geojson && this.data.geojson.length > 0) {
        this.data.geojson.forEach((geojson, index) => {
          this.map.addSource(`geojsonSource-${index}`, {
            type: "geojson",
            data: geojson.url,
          });

          this.map.addLayer({
            id: `geojsonLayer-fill-${index}`,
            type: "fill",
            source: `geojsonSource-${index}`,
            layout: {},
            paint: {
              "fill-color": this.Helper.colors(index),
              "fill-opacity": 0.5,
            },
            filter: ["==", "$type", "Polygon"],
          });

          this.map.addLayer({
            id: `geojsonLayer-line-${index}`,
            type: "line",
            source: `geojsonSource-${index}`,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": this.Helper.colors(index),
              // "line-width": 100,
            },
            filter: ["==", "$type", "Polygon"],
          });

          this.map.addLayer({
            id: `geojsonLayer-circle-${index}`,
            type: "circle",
            source: `geojsonSource-${index}`,
            paint: {
              "circle-radius": 6,
              "circle-color": this.Helper.colors(index),
            },
            filter: ["==", "$type", "Point"],
          });

          this.map.on("click", `geojsonLayer-fill-${index}`, (e) => {
            this.geojsonOnClickAction(e, geojson);
          });

          this.map.on("mouseenter", `geojsonLayer-fill-${index}`, () => {
            this.geojsonOnMouseEnterAction("pointer");
          });

          this.map.on("mouseleave", `geojsonLayer-fill-${index}`, () => {
            this.geojsonOnMouseEnterAction("");
          });

          this.map.on("click", `geojsonLayer-circle-${index}`, (e) => {
            this.geojsonOnClickAction(e, geojson);
          });

          this.map.on("mouseenter", `geojsonLayer-circle-${index}`, () => {
            this.geojsonOnMouseEnterAction("pointer");
          });

          this.map.on("mouseleave", `geojsonLayer-circle-${index}`, () => {
            this.geojsonOnMouseEnterAction("");
          });

          this.geojsons.push({
            id: `geojsonLayer-:type-${index}`,
            name: geojson.name,
            show: true,
            lineColor: this.Helper.colors(index),
            opacity: 100,
          });
        });
      }

      // Add geojson folder
      this.geojsonFolderNames = [];

      this.geojsonFolders = [];

      if (
        this.data.site_geojson_folders &&
        this.data.site_geojson_folders.length > 0
      ) {
        this.data.site_geojson_folders.forEach((folder, folderIndex) => {
          this.geojsonFolderNames.push(folder.name);

          folder.file.forEach((geojson, index) => {
            this.map.addSource(`geojsonFolderSource-${folderIndex}-${index}`, {
              type: "geojson",
              data: geojson.url,
            });

            this.map.addLayer({
              id: `geojsonFolderLayer-fill-${folderIndex}-${index}`,
              type: "fill",
              source: `geojsonFolderSource-${folderIndex}-${index}`,
              layout: {},
              paint: {
                "fill-color": this.Helper.colors(index),
                "fill-opacity": 0.5,
              },
              filter: ["==", "$type", "Polygon"],
            });

            this.map.addLayer({
              id: `geojsonFolderLayer-line-${folderIndex}-${index}`,
              type: "line",
              source: `geojsonFolderSource-${folderIndex}-${index}`,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": this.Helper.colors(index),
                // "line-width": 100,
              },
              filter: ["==", "$type", "Polygon"],
            });

            this.map.addLayer({
              id: `geojsonFolderLayer-circle-${folderIndex}-${index}`,
              type: "circle",
              source: `geojsonFolderSource-${folderIndex}-${index}`,
              paint: {
                "circle-radius": 6,
                "circle-color": this.Helper.colors(index),
              },
              filter: ["==", "$type", "Point"],
            });

            this.map.on(
              "click",
              `geojsonFolderLayer-fill-${folderIndex}-${index}`,
              (e) => {
                this.geojsonOnClickAction(e, geojson);
              }
            );

            this.map.on(
              "mouseenter",
              `geojsonFolderLayer-fill-${folderIndex}-${index}`,
              () => {
                this.geojsonOnMouseEnterAction("pointer");
              }
            );

            this.map.on(
              "mouseleave",
              `geojsonFolderLayer-fill-${folderIndex}-${index}`,
              () => {
                this.geojsonOnMouseEnterAction("");
              }
            );

            this.map.on(
              "click",
              `geojsonFolderLayer-circle-${folderIndex}-${index}`,
              (e) => {
                this.geojsonOnClickAction(e, geojson);
              }
            );

            this.map.on(
              "mouseenter",
              `geojsonFolderLayer-circle-${folderIndex}-${index}`,
              () => {
                this.geojsonOnMouseEnterAction("pointer");
              }
            );

            this.map.on(
              "mouseleave",
              `geojsonFolderLayer-circle-${folderIndex}-${index}`,
              () => {
                this.geojsonOnMouseEnterAction("");
              }
            );

            this.geojsonFolders.push({
              id: `geojsonFolderLayer-:type-${folderIndex}-${index}`,
              name: geojson.name,
              folder: folder.name,
              show: true,
              lineColor: this.Helper.colors(index),
              opacity: 100,
            });
          });
        });
      }

      // Add standoff shots
      this.map.loadImage(
        require("@/assets/icons/drone.png"),
        (error, image) => {
          if (error) throw error;

          this.map.addImage("drone", image);

          if (this.standoffShots.length > 0) {
            this.standoffShots.forEach((shot) => {
              this.addStandoffShotToMap(shot);
            });
          }
        }
      );

      // Add pin shots
      this.map.loadImage(
        require("@/assets/icons/360-video-player.png"),
        (error, image) => {
          if (error) throw error;

          this.map.addImage("pin-360", image);

          this.map.loadImage(
            require("@/assets/icons/multimedia.png"),
            (error, image) => {
              if (error) throw error;

              this.map.addImage("pin-video", image);

              if (this.pinShots.length > 0) {
                this.pinShots.forEach((shot) => {
                  this.addPinShotToMap(shot);
                });
              }
            }
          );
        }
      );

      // Add polygons
      if (this.polygons.length > 0) {
        this.polygons.forEach((polygon) => {
          this.addPolygonToMap(polygon);
        });
      }

      this.toggleGeojsonVisibility("all");

      this.toggleGeojsonFolderVisibility("all");

      this.toggleTileVisibility("all");

      this.toggleStandoffVisibility("all");

      this.togglePolygonVisibility("all");

      this.togglePinVisibility("all");
    },
    geojsonOnMouseEnterAction(cursor) {
      if (
        !this.showTools.measurement &&
        !this.showTools.standoff &&
        !this.showTools.polygon &&
        !this.showTools.pin
      ) {
        this.map.getCanvas().style.cursor = cursor;
      }
    },
    geojsonOnClickAction(e, geojson) {
      if (
        !this.showTools.measurement &&
        !this.showTools.standoff &&
        !this.showTools.polygon &&
        !this.showTools.pin
      ) {
        let gjsonFeatures = "";

        Object.keys(e.features[0].properties).forEach((feature) => {
          gjsonFeatures += `<tr>
                  <td>${feature}</td>
                  <td>${e.features[0].properties[feature]}</td>
                </tr>`;
        });

        let popupContainer = `<div class="mapbox-popup-container">
                <p class="popup-header">
                  ${this.Helper.formatGeojsonName(geojson.name)}
                </p>
                <div class="popup-content">
                  <table class="table table-bordered table-striped table-sm mb-0">
                    ${gjsonFeatures}
                  </table>
                </div>
              </div>`;

        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(popupContainer)
          .addTo(this.map);
      }
    },
    initMap() {
      return new Promise(async (resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        let mapCenter = [101.5179483, 3.075603444131706];

        if (this.data.lat && this.data.lng) {
          mapCenter = [this.data.lng, this.data.lat];
        }

        this.map = new mapboxgl.Map({
          container: "site-map",
          style: this.Helper.mapStyle(),
          center: mapCenter,
          zoom: 17,
        });

        this.$nextTick(() => {
          this.showMapButton = true;
        });

        this.map.on("load", async () => {
          resolve();
        });

        this.map.on("style.load", async () => {
          this.addMapLayers();

          this.$emit("toggle-spinner", false);
        });
      });
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();

      this.map = null;
    }
  },
};
</script>