<template>
  <div class="media-preview">
    <div class="media-header">
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <h1 class="d-flex justify-content-start align-items-center">
            {{ title }}
          </h1>
        </div>
      </div>
      <button class="btn border shadow-sm btn-close" @click="closePreview">
        &times;
      </button>
    </div>
    <div class="media-container d-flex flex-column p-0">
      <div
        class="gallery-group p-3 d-flex align-items-start overflow-auto"
        v-if="medias.length > 0"
        style="height: calc(100% - 57px)"
      >
        <div
          class="gallery-item rounded position-relative hover-parent"
          v-for="(media, index) in medias"
          :key="`media-upload-${index}`"
        >
          <button
            class="btn btn-sm btn-danger position-absolute hover-item"
            style="top: 1rem; right: 1rem; z-index: 2"
            @click.stop="removeMedia(index)"
          >
            <i class="fas fa-trash-alt" />
          </button>
          <div
            class="
              embed-responsive
              rounded
              embed-responsive-1by1
              shadow-sm
              bg-dark
            "
          >
            <img
              :src="media.preview"
              class="embed-responsive-item rounded"
              v-if="media.type == 'image'"
            />
            <div
              class="
                embed-responsive-item
                rounded
                d-flex
                flex-column
                justify-content-center
                align-items-center
                font-weight-medium
                bg-light
              "
              v-else-if="media.type == 'video'"
            >
              <img src="@/assets/icons/camcoder.svg" class="icon-sm mb-2" />
              VIDEO
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          py-5
          text-center
          flex-fill
          d-flex
          justify-content-center
          align-items-center
        "
        v-else
      >
        No image selected
      </div>
      <div class="media-footer">
        <button class="btn btn-primary" @click="addMedia()">Add media</button>
        <button
          class="btn btn-success"
          @click="startUpload()"
          :hidden="medias.length < 1"
        >
          Start upload
        </button>
      </div>
    </div>
    <spinner
      :show="isLoading"
      :text="`Uploaded ${uploadedCount}/${medias.length}`"
    />
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import Swal from "sweetalert2";

export default {
  name: "media-preview",
  props: ["title", "parentId", "parentEndpoint", "refName", "refId", "field"],
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      medias: [],
      uploadedCount: 0,
      referenceId: this.refId,
    };
  },
  methods: {
    removeMedia(index) {
      this.medias = this.medias.filter((m, idx) => idx != index);
    },
    async startUpload() {
      if (this.medias.length > 0) {
        this.isLoading = true;

        if (!this.refId && this.parentId) {
          const [call, err] = await this.Helper.handle(
            this.API.post("site-galleries", {
              name: "Gallery Images",
              site: this.parentId,
            })
          );

          if (!err && call.status == 200) {
            this.referenceId = call.data._id;
          }
        }

        this.uploadLoop(0);
      }
    },
    async uploadLoop(index) {
      await this.uploadMedia(index);

      if (index <= this.medias.length) {
        this.uploadLoop(index + 1);
      } else {
        Swal.fire(
          "<h5 class='mb-0'>Upload completed</h5>",
          "",
          "success"
        ).then(() => {
          this.isLoading = false;

          this.closePreview("update");
        });
      }
    },
    async uploadMedia(index) {
      let media = this.medias[index];

      if (media) {
        const formData = new FormData();

        let url = "upload";

        formData.append("files", media.file, media.file.name);
        formData.append("ref", this.refName);
        formData.append("refId", this.referenceId);
        formData.append("field", this.field);

        const [call, err] = await this.Helper.handle(
          this.API.postForm(url, formData)
        );

        this.uploadedCount += 1;

        if (!err && call.status == 200) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    addMedia() {
      let uploader = document.createElement("input");

      uploader.type = "file";
      uploader.accept = "video/*, image/*";
      uploader.multiple = true;

      uploader.onchange = (event) => {
        if (event.target.files.length < 1) return;

        const currentMediaCount = this.medias.length;

        let files = event.target.files;

        Array.from(files).forEach((file, index) => {
          if (file.type.includes("image")) {
            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (readerEvent) => {
              this.$nextTick(() => {
                this.medias.push({
                  file: file,
                  preview: readerEvent.target.result,
                  type: "image",
                });
              });
            };
          } else if (file.type.includes("video")) {
            this.medias.push({
              file: file,
              type: "video",
            });
          }
        });
      };

      uploader.click();
    },
    closePreview(e) {
      if (!this.isLoading) {
        this.$nextTick(() => {
          this.$emit("close", e);
        });
      }
    },
  },
  beforeDestroy() {
    this.closePreview();
  },
};
</script>